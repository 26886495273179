<template>
  <div>
    <!--Main Slider-->
    <section>
      <flickity class="flickity" ref="flickity" :options="flickityOptions">
        <img class="carousel-cell"
             src="../../assets/portal/images/main-slider/slide3.jpg"/>
        <img class="carousel-cell"
             src="../../assets/portal/images/main-slider/slide2.jpg"/>
        <img class="carousel-cell"
             src="../../assets/portal/images/main-slider/slide1.jpg"/>
      </flickity>
    </section>
    <!--Main Slider  end-->

    <!-- Welcome sec -->
    <div class="welcomesec secpadd">
      <div class="container">
        <div class="fh-section-title clearfix  text-center version-dark paddbtm20">
          <h2 v-html="getTranslation('Welcome to Cargo Jet')"></h2>
        </div>
        <p class="haeadingpara text-justify paddbtm40">
          <span class="ml-3"> {{ getTranslation('Cargo Jet is') }}&nbsp;</span>
          <span v-html="getTranslation('cargoJetDescription')"></span></p>

        <div class="welservices row">
          <div class="col-md-4 col-sm-6">
            <div class="fh-icon-box icon-type-theme_icon style-1 version-dark hide-button icon-left">
              <span class="fh-icon"><i class="fa fa-plane"></i></span>
              <h4 class="box-title"><a href="javascript:void(0)">{{ getTranslation('Air Shipment') }}</a></h4>
              <div class="desc">
                <p>{{ getTranslation('airShipmentDescrition') }}</p>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6">
            <div class="fh-icon-box icon-type-theme_icon style-1 version-dark hide-button icon-left">
              <span class="fh-icon"><i class="fa fa-subway"></i></span>
              <h4 class="box-title"><a href="javascript:void(0)">{{ getTranslation('Rail shipment') }}</a></h4>
              <div class="desc">
                <p>{{ getTranslation('railShipmentDescrption') }}</p>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6">
            <div class="fh-icon-box icon-type-theme_icon style-1 version-dark hide-button icon-left">
              <span class="fh-icon"><i class="fa fa-truck"></i></span>
              <h4 class="box-title"><a href="javascript:void(0)">{{ getTranslation('Freight Shipment') }}</a></h4>
              <div class="desc">
                <p>{{ getTranslation('freightShipmentDesc') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Welcome sec   end-->


    <!--home counters -->
    <section class="homecounts">
      <div class="container">
        <h2 class="count-title" v-html="getTranslation('newthinkingCargoJet')"></h2>
        <h4 class="count-title" v-html="getTranslation('newthinkingDesc')"></h4>
        <div class="row">
          <div class="col-lg-3 col-md-3 col-xs-12">
            <div class="fh-counter icon-type-none">
              <span class="fh-icon"><i class="flaticon-international-delivery"></i></span>
              <div class="counter">
                <p class="icon-description">{{ getTranslation('onMarketSince') }}</p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-xs-12">
            <div class="fh-counter icon-type-none">
              <span class="fh-icon"><i class="flaticon-shield"></i></span>
              <div class="counter">
                <p class="icon-description">{{ getTranslation('comfortPlatform') }}</p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-xs-12">
            <div class="fh-counter icon-type-none">
              <span class="fh-icon"><i class="flaticon-people-3"></i></span>
              <div class="counter">
                <p class="icon-description">{{ getTranslation('professionalsHelp') }}</p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-xs-12">
            <div class="fh-counter icon-type-none">
              <span class="fh-icon"><i class="flaticon-transport-10"></i></span>
              <div class="counter">
                <p class="icon-description">{{ getTranslation('allCargoType') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--home counters end -->

    <!--why choose us -->
    <section class="whychoose-1">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 col-md-6  secpaddlf">
            <div class="fh-section-title clearfix  text-left version-dark paddbtm40">
              <h2>{{ getTranslation('whyUs') }}</h2>
            </div>
            <div class="fh-icon-box  style-2 icon-left has-line">
              <span class="fh-icon"><i class="flaticon-box-1"></i></span>
              <h4 class="box-title"><span>{{ getTranslation('chooseComfort') }}</span></h4>
              <div class="desc">
                <p>{{ getTranslation('chooseComfortDesc') }}</p>
              </div>
            </div>
            <div class="fh-icon-box  style-2 icon-left has-line">
              <span class="fh-icon"><i class="flaticon-check"></i></span>
              <h4 class="box-title"><span>{{ getTranslation('optimalPrice') }}</span></h4>
              <div class="desc">
                <p>{{ getTranslation('optimalPriceDesc') }}</p>
              </div>
            </div>
            <div class="fh-icon-box  style-2 icon-left has-line">
              <span class="fh-icon"><i class="flaticon-route"></i></span>
              <h4 class="box-title"><span>{{ getTranslation('onlineTracking') }}</span></h4>
              <div class="desc">
                <p>{{ getTranslation('onlineTrackingDesc') }}</p>
              </div>
            </div>
            <div class="fh-icon-box  style-2 icon-left">
              <span class="fh-icon"><i class="flaticon1-file"></i></span>
              <h4 class="box-title"><span>{{ getTranslation('customsService') }}</span></h4>
              <div class="desc">
                <p>{{ getTranslation('customsServiceDesc') }}</p>
              </div>
            </div>
          </div>
          <div class="col-lg-5 col-lg-offset-2 col-md-6 quofrm1  secpaddlf">
            <div class="fh-section-title clearfix  text-left version-dark paddbtm40">
              <h2>{{ getTranslation('leaveRequest') }}</h2>
            </div>
            <form @submit.prevent="requestCallback">
              <div class="fh-form-1 fh-form">
                <div class="row fh-form-row">
                  <div class="col-md-12 col-xs-12 col-sm-12">
                    <p class="field">
                      <input name="your-name" required="required" v-model="callback.name" value="" :placeholder="getTranslation('Full Name')+'*'" type="text">
                      <span v-if="$v.callback.name.$error" class="invalid-feedback">
                      {{ getTranslation('requiredField') }}
                    </span>
                    </p>

                  </div>
                  <div class="col-md-12 col-xs-12 col-sm-12">
                    <p class="field">
                      <input name="your-email"  required="required" v-model="callback.email" value="" :placeholder="getTranslation('email')+'*'" type="email">
                      <span v-if="$v.callback.email.$error" class="invalid-feedback">
                      {{ getTranslation('requiredField') }}
                    </span>
                    </p>

                  </div>
                  <div class="col-md-12 col-xs-12 col-sm-12">
                    <p class="field">
                      <input name="your-email" v-model="callback.phone" value="" :placeholder="getTranslation('Phone number')" type="text">
                    </p>
                  </div>
                  <div class="col-md-12 col-xs-12 col-sm-12">
                    <p class="field single-field">
                      <textarea cols="40" :placeholder="getTranslation('Message')" v-model="callback.message"></textarea>
                    </p>
                  </div>
                  <div class="col-md-12 col-sm-12 col-xs-12">
                    <p class="field submit">
                      <input :value="getTranslation('submit')" class="fh-btn" type="submit">
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    <!--why choose us end -->

  </div>
</template>

<script>
import Flickity from 'vue-flickity';

import required from "vuelidate/lib/validators/required";

export default {
  name: "Index",
  components: { Flickity },
  data() {
    return {
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: false,
        pageDots: true,
        wrapAround: true,
        freeScroll: true,
        autoPlay: true
      },
      callback: {
        name: '',
        email: '',
        phone: '',
        message: ''
      }
    }
  },
  created() {
    this.setPortalHeadings({
      title: this.getTranslation('Cargo JET'),
      breadcrumbs: []
    });
  },
  methods: {
    requestCallback() {

      this.$v.callback.$touch();

      if ( this.$v.callback.$invalid )
        return;

      return this.axios.post(this.$urls.portal.requestCallback.url, this.callback)
          .then(() => {
            Swal.fire({
              icon: 'success',
              title: this.getTranslation('You request is sent, we will contact you ASAP'),
            });
          }).catch(() => {

          }).finally(() => {
            this.resetCallback();
          });
    },
    resetCallback() {
      this.$v.callback.$reset();
      this.callback = {
        name: '',
        email: '',
        phone: '',
        message: ''
      };
    },
  },
  validations: {
    callback: {
      email: {
        required
      },
      name:
          {
            required
          },
    }
  }
}
</script>


<style scoped>
</style>